<div class="cfx-card big" *ngIf="isAdmin()">
  <p-panel>
    <p-header>Mitspieler</p-header>
    <div class="ui-g ui-g-nopad">
      <div *ngFor="let player of user_mail_list" class="float-input ui-g-nopad">
        <div class="ui-g-4 ui-g-nopad">{{player.nickname}}: </div>
        <div class="ui-g-8 ui-g-nopad">{{player.user_mail}}</div>
      </div>
    </div>
  </p-panel>
</div>
<div class="cfx-container cfx-dashboard" *ngIf="!isAdmin()">
  <p>Du bist kein Admin und hast deshalb aktuell keinen Zugriff auf diese Seite!</p>
</div>
