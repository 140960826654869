import { Routes, RouterModule } from '@angular/router'

// Components
import { RegisterComponent } from './component/register/register.component'
import { ScoreDashboardComponent } from './component/score_dashboard/score_dashboard.component'
import { BetDashboardComponent } from './component/bet_dashboard/bet_dashboard.component'
import { LoginComponent } from './component/login/login.component'
import { ResetComponent } from './component/reset/reset.component'
import { ActivationComponent } from './component/activation/activation.component'
import { AuthGuard } from './service/authentication.guard'
import { RulesComponent } from './component/rules/rules.component'
import { ChangePWComponent } from './component/changePW/changePW.component'
import { PlayersComponent } from './component/players/players.component'

/**
 * Definition of the existing routes in the usermanagement app
 * @type {({path: string; component: any; canActivate: AuthGuard[]})}
 */
const routes: Routes = [
  // Root
  { path: 'bets', component: BetDashboardComponent, canActivate: [AuthGuard] },
  { path: 'home', component: ScoreDashboardComponent, canActivate: [AuthGuard] },
  { path: '', component: ScoreDashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'changePW', component: ChangePWComponent, canActivate: [AuthGuard] },
  { path: 'rules', component: RulesComponent },
  { path: 'regfailed', component: ActivationComponent },
  { path: 'regsuccess', component: ActivationComponent },
  { path: 'pwresetfailed', component: ActivationComponent },
  { path: 'pwresetsuccess', component: ActivationComponent },
  { path: 'players', component: PlayersComponent }
]

// - Updated Export
export const routing = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
