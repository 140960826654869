import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { UserService } from '../../service/user.service'
import { Game } from '../../entity/game'
import { MessageService } from 'primeng/api'
import { getCurrentUser } from '../../helpers/helperFunctions'

/**
 * Main app component. Lists all the games for a user
 */
@Component({
  selector: 'pseudokenner-bet-dashboard',
  templateUrl: './bet_dashboard.component.html',
  styleUrls: ['./bet_dashboard.component.css']
})
export class BetDashboardComponent implements OnInit {
  title = 'bet-dashboard'
  /**
   * array of games shown on the list
   * @type {any[]}
   */
  bets: Game[] = []
  players: any[]
  champion_bets: any[]
  participants: any[]
  game_days: any[]
  games: any[]
  bets_per_game: any[]
  currentGreeting: any
  champion_team: any
  navigationSubscription: any
  currentDayIndex: number = 0

  /**
   * Constructor
   * @param {Router} router
   * @param {UserService} userService service that provides functions for {User} instances
   * @param {MessageService} messageService service to show (warn/success/error) messages to the user
   */
  constructor (private readonly userService: UserService, private readonly router: Router, private readonly messageService: MessageService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadAllBets()
      }
    })
  }

  ngOnInit () {
    this.loadAllBets()
  }

  get currentNickname () {
    return JSON.parse(getCurrentUser()).nickname ?? ''
  }

  get currentPayout () {
    return JSON.parse(getCurrentUser()).payout ?? ''
  }

  get currentJackpot () {
    return JSON.parse(getCurrentUser()).jackpot ?? ''
  }

  /**
   * load all games into the dashboard
   */
  loadAllBets () {
    this.userService.getAllBets(JSON.parse(getCurrentUser()).user).subscribe(
      data => {
        this.players = data.user
        this.participants = data.participants
        if (new Date() > new Date(data.games[0].category_deadline)) {
          this.champion_bets = data.championBets
        } else {
          this.champion_bets = []
        }
        if (this.champion_bets) {
          this.champion_bets.forEach(bet => this.setChampionName(bet))
        }
        this.champion_team = data.champion_team
        this.games = data.games
        this.bets = data.data
        this.currentGreeting = data.greeting

        this.groupByGame()
        this.groupByDate()
        this.setCurrentDayIndex()
      },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: err })
      }
    )
  }

  filterGames (user: number) {
    const visibleGames = this.games.filter(game => new Date(game.category_deadline) < new Date()).map(game => game.game_id)
    return this.bets.filter(bet => bet.user === user && visibleGames.includes(bet.ID))
  }

  private setChampionName (champion_id: any) {
    champion_id.champion_name = this.participants.filter(participant => participant.value === champion_id.champion_team)[0].label || ''
    champion_id.champion_usernick = this.players.filter(username => username.user === champion_id.champion_user)[0].nickname || ''
  }

  isAdmin (): boolean {
    return JSON.parse(getCurrentUser()).role === 2
  }

  /**
   *
   */
  updateBets () {
    const games = this.bets.sort((n1, n2) => n1.ID - n2.ID).filter(game => game.result_team1 !== null && game.result_team2 !== null)
    const gameIDs = games.map(item => item.ID)
    const uniqueIDs = Array.from(new Set(gameIDs))
    let jackpot = 0
    for (const gameID of uniqueIDs) {
      const gameBets = this.bets.filter(bet => bet.ID === gameID)
      const correctBets: Game[] = []
      for (const gameBet of gameBets) {
        if (gameBet.bet_team1 === gameBet.result_team1 && gameBet.bet_team2 === gameBet.result_team2) {
          correctBets.push(gameBet)
        }
      }
      if (correctBets.length === 0) {
        jackpot += gameBets[0].category_bet_amount * this.players.length
      } else {
        for (const correctBet of correctBets) {
          correctBet.bet_amount = gameBets[0].category_bet_amount * this.players.length / correctBets.length
        }
      }
    }
    const bet_array = this.bets.map(bet => {
      return { bet_id: bet.bet_id, bet_gain: bet.bet_amount || 0 }
    })
    const correct_champion_bets = this.champion_bets.filter(bet => bet.champion_team === this.champion_team.champion_team_id)
    const champion_array = this.champion_bets.map(bet => {
      if (bet.champion_team === this.champion_team.champion_team_id) {
        bet.champion_amount = this.champion_team.champion_bet_amount * this.players.length / correct_champion_bets.length
      } else {
        bet.champion_amount = 0
      }
      return { champion_id: bet.champion_id, champion_amount: bet.champion_amount || 0 }
    })
    const user_gains: any[] = []
    for (const player of this.players) {
      const gain = this.bets.filter(bet => bet.user === player.user && bet.bet_amount)
        .map(game => game.bet_amount)
      const championGain = this.champion_bets.filter(bet => {
        return bet.champion_user === player.user
      })
      gain.push(championGain.length > 0 ? championGain[0].champion_amount : 0)
      user_gains.push({ user_id: player.user, user_gain: gain.reduce((a, b) => a + b, 0) || 0.0 })
    }
    const cfxUser = getCurrentUser()
    this.userService.updateBetGains(bet_array, jackpot, champion_array, user_gains, JSON.parse(cfxUser).user, JSON.parse(cfxUser).role).subscribe(
      data => {
        this.router.navigate(['/bets'])
        this.messageService.add({ severity: 'success', summary: 'Aktualisierung erfolgreich!', detail: '' })
      },
      err => {
        console.error(err)
        this.messageService.add({ severity: 'Error!', summary: 'Gewinne konnten nicht aktualisiert werden' })
      }
    )
  }

  /**
   *
   */
  private groupByDate () {
    if (this.bets) {
      const gameTimes = this.bets.map(item => new Date(item.date))

      const uniqueDays = gameTimes.map(s => s.toDateString())
        .filter((s, i, a) => a.indexOf(s) === i)
        .map(s => new Date(s))
      this.game_days = uniqueDays.map(day => {
        return {
          day: day.getDate().toString() + '.' + (day.getMonth() + 1).toString(),
          games: this.bets_per_game
            .filter(game => {
              const gameDate = new Date(game.bets[0].date)
              if (new Date() > new Date(game.category_deadline) && gameDate.toDateString() === day.toDateString()) {
                return game
              }
              return null
            })
        }
      })
    }
  }

  /**
   *
   */
  groupByGame () {
    if (this.games) {
      this.bets_per_game = this.games.map(game => {
        return {
          game: game.game_id,
          category_deadline: new Date(game.category_deadline),
          bets: this.bets.filter(bet => {
            if (bet.ID === game.game_id && bet.date) {
              return bet
            }
            return null
          })
        }
      })
    }
  }

  getPlayerBet (game_id: number, player: number) {
    const currentBet = this.bets.find(bet => bet.ID === game_id && bet.user === player)
    return (currentBet != null) ? currentBet.bet_team1.toString() + ':' + currentBet.bet_team2.toString() : '-'
  }

  isCorrectBet (game: any, player: number): string {
    const currentBet = this.bets.find(bet => bet.ID === game.game && bet.user === player)
    if (currentBet != null) {
      if (currentBet.bet_team1 === currentBet.result_team1 && currentBet.bet_team2 === currentBet.result_team2) {
        return 'bet-cell correct'
      } else {
        return 'bet-cell'
      }
    } else {
      return 'bet-cell'
    }
  }

  resetBets () {
    const cfxUser = getCurrentUser()
    this.userService.resetBets(JSON.parse(cfxUser).user, JSON.parse(cfxUser).role).subscribe(
      data => {
        this.router.navigate(['/bets'])
        this.messageService.add({ severity: 'success', summary: 'Aktualisierung erfolgreich!', detail: '' })
      },
      err => {
        console.error(err)
        this.messageService.add({ severity: 'Error!', summary: 'Gewinne konnten nicht aktualisiert werden' })
      }
    )
  }

  setCurrentDayIndex (): void {
    const date = new Date()
    const today = (date.getDate()).toString() + '.' + (date.getMonth() + 1).toString()
    const index = this.game_days.findIndex(day => day.day === today)
    this.currentDayIndex = index > 0 ? index : 0
  }
}
