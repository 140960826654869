<div class="cfx-card">
  <form name="form" (ngSubmit)="f.form.valid && changePW()" #f="ngForm">
    <p-panel>
      <p-header>Passwort ändern</p-header>
      <div class="input-section">
        <div class="float-input">
          <span class="p-float-label">
            <input pInputText id="usermail" size="25" name="usermail" type="email" [(ngModel)]="user.usermail" required disabled>
            <label for="usermail" data-error="ungültige Addresse"></label>
          </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
            <input pInputText id="password" name="password" size="25" type="password" [(ngModel)]="user.password" required>
            <label for="password">Altes Passwort:</label>
          </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
            <input pPassword minlength="8" size="25" id="newpassword" name="newpassword" type="password"
                 [(ngModel)]="newPW"
                 required>
            <label for="newpassword" data-error="Passwort zu kurz (min 8 Zeichen)">Neues Passwort:</label>
          </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
            <input pInputText minlength="8" size="25" id="newpasswordconfirm" name="newpasswordconfirm" type="password"
                 [(ngModel)]="newPWConfirm" required>
            <label for="newpasswordconfirm" data-error="Passwort zu kurz (min 8 Zeichen)">Neues Passwort bestätigen:</label>
          </span>
        </div>
      </div>
      <p-footer>
        <button pButton label="Ändern" [disabled]="!f.form.valid || !pwMatch()" type="submit"></button>
        <button pButton label="Abbrechen" class="p-button-secondary" [routerLink]="['/home']"></button>
      </p-footer>
    </p-panel>
  </form>
</div>
