import { Component, OnInit } from '@angular/core'
import { UserService } from '../../service/user.service'
import { Router } from '@angular/router'
import { MessageService } from 'primeng/api'
import { getCurrentUser } from '../../helpers/helperFunctions'

/**
 * Component to reset user's password
 */
@Component({
  selector: 'pseudokenner-reset',
  templateUrl: './players.component.html'
})
export class PlayersComponent implements OnInit {
  title = 'players'
  user_mail_list: any[]

  constructor (private readonly userService: UserService, private readonly router: Router, private readonly messageService: MessageService) {
  }

  ngOnInit () {
    this.getUserMail()
  }

  isAdmin (): boolean {
    return JSON.parse(getCurrentUser()).role === 2
  }

  /**
   * send reset information to the user service
   */
  getUserMail () {
    this.userService.getUserMail(JSON.parse(getCurrentUser()).user, JSON.parse(getCurrentUser()).role).subscribe(
      data => {
        this.user_mail_list = data.data
      },
      err => {
        console.error(err)
        this.messageService.add({ severity: 'Error!', summary: 'Liste konnte nicht generiert werden!' })
      }
    )
  }
}
