import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment.prod'
import { User } from '../entity/user'
import * as SHA512 from '../../../node_modules/crypto-js/sha512'
import { Game } from '../entity/game'
import { generateErrorMsg } from '../entity/cfx-const'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { getPwHash } from '../helpers/helperFunctions'

/**
 * Service class for the user functions
 * Created by flaviokeller on 26.07.17.
 */
@Injectable()
export class UserService {
  public token: string

  constructor (private readonly http: HttpClient) {
  }

  /**
   * get a single user by user name
   * @param {string} username
   * @returns {Observable<User>}
   */
  getUser (username: string): Observable<User> {
    return this.http.get<User>(environment.host + '/users')
      .pipe(
        map(response => response),
        catchError(error => throwError(generateErrorMsg(error.error.error)))
      )
  }

  /**
   *
   * @param {string} user
   * @returns {Observable<any>}
   */
  getChampionBet (user: string): Observable<any> {
    const params = {
      name: btoa(user),
      pw: getPwHash()
    }
    return this.http.get<any>(environment.host + '/pseudokennermodules/moduleGetChampionBet.php', { params })
      .pipe(
        map(response => response.data),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * get all games for a single user
   * @param {string} user
   * @returns {Observable<Game[]>}
   */
  getAllGames (user: string): Observable<any> {
    const params = {
      command: 'getAllGames',
      name: btoa(user),
      pw: getPwHash(),
      app: 'pseudokenner'
    }
    return this.http.get<any>(environment.host + '/pseudokennermodules/moduleGetBets.php', { params })
      .pipe(
        map(response => response.data),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   *
   * @param {string} user
   * @param {Game[]} games
   * @returns {Observable<Game[]>}
   */
  saveBetsForUser (user: string, games: Array<Game | undefined>): Observable<Game[]> {
    const params = {
      bets: [
        { command: 'saveBets' },
        { name: btoa(user) },
        { pw: getPwHash() },
        { app: 'pseudokenner' },
        { games: JSON.stringify(games) }]
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    const options = {
      headers
    }
    return this.http.post(environment.host + '/pseudokennermodules/moduleSaveBets.php', params, options)
      .pipe(
        map(response => response as Game[]),
        catchError(error => throwError(generateErrorMsg(error.json().error)))
      )
  }

  /**
   *
   * @param {string} user
   * @param {string} champion
   * @returns {Observable<string>}
   */
  saveChampionBet (user: string, champion: string): Observable<string> {
    const params = {
      name: btoa(user),
      pw: getPwHash(),
      champion
    }
    return this.http.get<string>(environment.host + '/pseudokennermodules/moduleSaveChampionBet.php', { params })
      .pipe(
        map(response => response),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * login a user
   * @param {User} user
   * @returns {Observable<any>}
   */
  login (user: User) {
    const userMailBase64 = btoa(user.usermail)
    const pwAndUserMailHash = btoa(user.password + userMailBase64)
    const hashedPw = SHA512(pwAndUserMailHash) as string
    const verifyHash = SHA512(hashedPw + userMailBase64)
    const params = {
      command: 'userState',
      name: btoa(user.usermail),
      pw: verifyHash.toString(),
      app: 'pseudokenner',
      api: 'useradmin'
    }
    return this.http.get(environment.host + '/useradminmodules/frontendUserState.php', { params })
      .pipe(
        map((data: any) => {
          this.token = data.token
          localStorage.setItem('cfx-user', JSON.stringify(data))
          localStorage.setItem('cfx-pwhash', verifyHash.toString())
        }
        ),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * register a new user
   * @param {User} user
   * @returns {Observable<any>}
   */
  register (user: User) {
    const pwAndUserMailHash = btoa(user.password + btoa(user.usermail))
    const hashedPw = SHA512(pwAndUserMailHash)
    const params = {
      command: 'addUser',
      name: btoa(user.usermail),
      nickname: user.nickname,
      pw: hashedPw.toString(),
      agree: 'YES',
      app: 'pseudokenner',
      api: 'useradmin'
    }
    return this.http.get<any>(environment.host + '/useradminmodules/frontendNewUser.php', { params })
      .pipe(
        map(response => response),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * request the reset of the password of a user
   * @param {User} user
   * @returns {Observable<any>} JSON of the server response
   */
  reset (user: User) {
    const params = {
      command: 'rupwr',
      name: btoa(user.usermail),
      app: 'pseudokenner'
    }
    return this.http.get<any>(environment.host + '/useradminmodules/frontendPasswordResetRequest.php', { params })
      .pipe(
        map(async (response: Response) => await response.json()),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * Change the password of a user
   * @param {User} user
   * @param {string} newPW hashed new password
   * @returns {Observable<any>} JSON of the server response
   */
  changePW (user: User, newPW: string) {
    const userMailBase64 = btoa(user.usermail)
    const pwAndUserMailHash = btoa(user.password + userMailBase64)
    const hashedPw = SHA512(pwAndUserMailHash) as string
    const verifyHash = SHA512(hashedPw + userMailBase64)
    const pwAndUserMailHashNew = btoa(newPW + userMailBase64)
    const hashedPwNew = SHA512(pwAndUserMailHashNew) as string
    const verifyHashNew = SHA512(hashedPwNew + userMailBase64)

    const params = {
      command: 'chpw',
      name: userMailBase64,
      pw: verifyHash.toString(),
      pwnew: hashedPwNew.toString(),
      agree: 'YES',
      app: 'pseudokenner'
    }

    return this.http.get(environment.host + '/useradminmodules/frontendNewPassword.php', { params })
      .pipe(
        map(
          (response: Response) => {
            // response.json()
            localStorage.setItem('cfx-pwhash', verifyHashNew.toString())
          }),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  updateBetGains (bet_array: any[], jackpot: number, champion_array: any[], user_gains: any, user: string, role: string): Observable<any> {
    const params = {
      gains: [
        { command: 'saveBets' },
        { name: btoa(user) },
        { pw: getPwHash() },
        { bets: JSON.stringify(bet_array) },
        { champion_bets: JSON.stringify(champion_array) },
        { jackpot: jackpot.toString() },
        { user_gains: JSON.stringify(user_gains) },
        { role }
      ]
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    const options = {
      headers
    }
    return this.http.post(environment.host + '/pseudokennermodules/moduleUpdateBetGains.php', params, options)
      .pipe(
        map(response => response),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * get all games for a single user
   * @param {string} user
   * @returns {Observable<Game[]>}
   */
  getAllBets (user: string): Observable<any> {
    const params = {
      command: 'getAllGames',
      name: btoa(user),
      pw: getPwHash(),
      app: 'pseudokenner'
    }
    return this.http.get(environment.host + '/pseudokennermodules/moduleGetAllBets.php', { params })
      .pipe(
        map(response => response),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   *
   * @param {string} user
   * @param {string} role
   * @returns {null}
   */
  resetBets (user: string, role: string) {
    const params = {
      command: 'getUserMail',
      name: btoa(user),
      role,
      pw: getPwHash(),
      app: 'pseudokenner'
    }
    return this.http.get(environment.host + '/pseudokennermodules/moduleResetBetGains.php', { params })
      .pipe(
        map(response => response),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   *
   * @param {string} user
   * @param {string} role
   * @returns {Observable<string[]>}
   */
  getUserMail (user: string, role: string): Observable<any> {
    const params = {
      command: 'getUserMail',
      name: btoa(user),
      pw: getPwHash(),
      role,
      app: 'pseudokenner'
    }
    return this.http.get(environment.host + '/pseudokennermodules/moduleGetPlayers.php', { params })
      .pipe(
        map(response => response),
        catchError(error => generateErrorMsg(error.error.error))
      )
  }

  /**
   * Logout the current user
   */
  logout () {
    localStorage.clear()
  }
}
