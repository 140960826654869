<nav class="cfx-navbar cfx-container">
  <a [routerLink]="['/home']" class="logo-image-link"><i class="fa fa-fw fa-soccer-ball-o" style="color: #71AB6B;"></i>Pseudokenner 2022</a>
  <div class="cfx-nav-links">
    <a [routerLink]="['/rules']">Regeln</a>
    <a *ngIf="checkAdmin()" [routerLink]="['/players']">Mitspielerliste</a>
    <a *ngIf="checkCredentials() && !checkAdmin()" [routerLink]="['/home']">Meine Tipps</a>
    <a *ngIf="checkCredentials()" [routerLink]="['/bets']">Alle Tipps</a>
    <a *ngIf="checkCredentials()" [routerLink]="['/changePW']">Passwort ändern</a>
    <a *ngIf="checkCredentials()" (click)="logout()" title="Log out"><i class="fa fa-sign-out"></i></a>
  </div>
</nav>
<p-toast></p-toast>
<router-outlet></router-outlet>
