<div class="cfx-card">
  <form name="form" (ngSubmit)="register()" #f="ngForm">
    <p-panel>
      <p-header>Registrieren</p-header>
      <div class="input-section">
        <div class="float-input">
          <span class="p-float-label">
          <input pInputText id="usermail" name="usermail" type="email" [(ngModel)]="user.usermail" required
                 size="25" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
          <label for="usermail" data-error="invalid address">E-Mail:</label>
            </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
          <input pInputText id="nickname" name="nickname" type="text" [(ngModel)]="user.nickname" required
                 size="25">
          <label for="nickname" data-error="invalid address">Nickname:</label>
            </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
          <input pPassword="" minlength="8" id="password" name="password" type="password"
                 size="25" [(ngModel)]="user.password" required>
          <label for="password" data-error="Password too short (min 8 characters)">Passwort:</label>
          </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
          <input pInputText minlength="8" id="confirmpassword" name="confirmpassword" type="password"
                 size="25" [(ngModel)]="confirmpassword" required>
          <label for="confirmpassword" data-error="Passwort zu kurz (min 8 Zeichen)">Passwort bestätigen:</label>
          </span>
        </div>
      </div>
      <p-footer>
        <button pButton type="submit" [disabled]="!f.form.valid || !pwMatch()" label="Registrieren"></button>
        <button pButton label="Abbrechen" class="p-button-secondary" [routerLink]="['/login']"></button>

      </p-footer>
    </p-panel>
  </form>
</div>
