import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { User } from '../../entity/user'
import { UserService } from '../../service/user.service'
import { MessageService } from 'primeng/api'
import { getCurrentUser } from '../../helpers/helperFunctions'

/**
 * Component to let the user change the password
 */
@Component({
  selector: 'pseudokenner-changepw',
  templateUrl: './changePW.component.html'
})
export class ChangePWComponent implements OnInit {
  title = 'changePW'
  user = new User()
  /**
   * the new password input
   */
  newPW: any
  /**
   * the password confirm input value
   */
  newPWConfirm: any

  /**
   * Constructor
   * @param {Router} router
   * @param {UserService} userService service that provides functions for {User} instances
   * @param {MessageService} messageService service to show (warn/success/error) messages to the user
   */
  constructor (private readonly router: Router,
    private readonly userService: UserService,
    private readonly messageService: MessageService) {
  }

  ngOnInit () {
    this.user.usermail = JSON.parse(getCurrentUser()).user
  }

  /**
   * function to change the password
   */
  changePW () {
    if (this.newPW.length < 8) {
      this.messageService.add({ severity: 'warn', summary: 'Neues Passwort zu kurz!', detail: '' })
    } else if (!this.pwMatch()) {
      this.messageService.add({ severity: 'warn', summary: 'Invalid input!', detail: 'Passwort und Bestätigung stimmen nicht überein!' })
    } else {
      this.userService.changePW(this.user, this.newPW).subscribe(
        data => {
          this.router.navigate(['/home'])
          this.messageService.add({ severity: 'success', summary: 'Passwort erfolgreich geändert', detail: '' })
        },
        error => {
          this.user = new User()
          this.newPW = ''
          this.messageService.add({ severity: 'error', summary: 'Fehler!', detail: error })
        })
    }
  }

  /**
   * Check that the password and the confirm password inputs are equal
   * @returns {boolean}
   */
  pwMatch (): boolean {
    return this.newPW === this.newPWConfirm
  }
}
