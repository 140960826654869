import { Component } from '@angular/core'
import { User } from '../../entity/user'
import { UserService } from '../../service/user.service'
import { Router } from '@angular/router'
import { MessageService } from 'primeng/api'

/**
 * Component to reset user's password
 */
@Component({
  selector: 'pseudokenner-reset',
  templateUrl: './reset.component.html'
})
export class ResetComponent {
  title = 'reset'
  user = new User()

  constructor (private readonly userService: UserService, private readonly router: Router, private readonly messageService: MessageService) {
  }

  /**
   * send reset information to the user service
   */
  reset () {
    this.userService.reset(this.user).subscribe(
      data => {
        this.messageService.add({
          severity: 'success',
          summary: 'Passwort zurücksetzen erfolgreich',
          detail: 'Ein E-Mail wurde an ' + this.user.usermail + 'gesendet! Folge diesen Instruktionen'
        })
        this.router.navigate(['/login'])
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Fehler!', detail: error })
        this.user = new User()
      })
  }
}
