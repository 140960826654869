<div class="cfx-card">
  <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm">
    <p-panel>
      <p-header>Login</p-header>
      <div class="input-section">
        <div class="float-input">
          <span class="p-float-label">
          <input pInputText id="usermail" name="usermail" type="email" [(ngModel)]="user.usermail" required
                 size="25" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
          <label for="usermail" data-error="Invalid address">E-Mail:</label>
          </span>
        </div>
        <div class="float-input">
          <span class="p-float-label">
          <input pInputText name="password" id="password" type="password" size="25" [(ngModel)]="user.password"
                 required>
          <label for="password">Passwort:</label>
          </span>
        </div>
        <div class="float-input">
          <a [routerLink]="['/reset']">Passwort vergessen?</a>
        </div>
      </div>
      <p-footer>
        <button pButton label="Login" [disabled]="!f.form.valid" type="submit"></button>
        <button pButton label="Registrieren" class="p-button-secondary" [routerLink]="['/register']" type="button"></button>
      </p-footer>
    </p-panel>
  </form>
</div>
