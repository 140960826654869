import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

/**
 * Component to let the user change the password
 */
@Component({
  selector: 'pseudokenner-activation',
  templateUrl: './activation.component.html'
})
export class ActivationComponent implements OnInit {
  title = 'activation'
  /**
   * Constructor
   * @param {Router} router
   */
  constructor (public router: Router) {
  }

  ngOnInit (): void {

  }
}
