<div class="cfx-container cfx-dashboard">
  <h2>Hallo {{currentNickname}}!</h2>
  <button pButton *ngIf="isAdmin()" class="ui-button-cfx" (click)="updateBets()"
          label="Gewinnberechnung aktualisieren"></button>
  <button pButton *ngIf="isAdmin()" class="ui-button-cfx" (click)="resetBets()" label="Gewinne zurücksetzen"></button>
  <div *ngIf="currentGreeting">
    <h3>{{currentGreeting.title}}</h3>
    <p>{{currentGreeting.text}}</p>
  </div>
  <p>Dein Aktueller Gewinn beträgt <span class="payout-text">{{currentPayout || 0 | number : '1.0-2'}} CHF</span></p>
  <p>Im Jackpot befinden sich aktuell <span class="payout-text">{{currentJackpot || 0 | number : '1.0-2'}} CHF</span>
  </p>
  <div class="sub-container">
    <h2>Tipp-Übersicht</h2>
    <p class="small">Falls noch keine Resultate für ein Tag angezeigt werden, kann für diese Spiele noch getippt werden und sind noch nicht zur Ansicht freigegeben.</p>
    <p-tabView [(activeIndex)]="currentDayIndex" [scrollable]="true">
      <p-tabPanel [header]="day_bets.day" *ngFor="let day_bets of game_days">
        <div class="overflow-container">
        <div class="game-row">
          <div class="game-row">
            <div class="first-cell">
              <div class="table-header">Spiel:</div>
           </div>
          </div>
          <div class="header-offset">
            <div class="rotate" *ngFor="let player of players">
              <div><span>{{player.nickname}}</span></div>
            </div>
          </div>
        </div>

          <div class="game-row" *ngFor="let game of day_bets.games">
            <div class="first-cell">
              <div class="">
                {{game.bets[0].team1}} - {{game.bets[0].team2}}
              </div>
              <div class="score-cell">
                {{game.bets[0].result_team1}}:{{game.bets[0].result_team2}}
              </div>
            </div>
            <div class="">
              <div *ngFor="let player of players" [ngClass]="isCorrectBet(game, player.user)">
                {{getPlayerBet(game.game, player.user)}}
              </div>
            </div>
          </div>
        </div>
        </p-tabPanel>
    </p-tabView>
  </div>
  <div class="sub-container">
    <h2>Spieler-Tipps / Rangliste</h2>
    <p-accordion [multiple]="true">
      <p-accordionTab *ngFor="let player of players"
                      header="{{player.nickname}}, Aktueller Gewinn: {{player.payout | number : '1.0-2'}}"
                      class="ui-accordion-header-cfx">
        <p-dataView [value]="filterGames(player.user)">
          <ng-template let-group pTemplate="listItem">
            <div class="player-bet">
              <div class="player-bet-column game-fixture">{{group.ID}} {{group.team1}} - {{group.team2}}</div>
              <div class="player-bet-column game-result">{{group.result_team1 | number}} : {{group.result_team2 | number}}</div>
              <div class="player-bet-column">Tipp: {{group.bet_team1 | number}} : {{group.bet_team2 | number}}
                <i
                  [class]="group.result_team1 == group.bet_team1 && group.result_team2 == group.bet_team2 ? 'fa fa-check fa-fw color-green' : ''"></i>
                
              </div>
              <div class="player-bet-column">Gewinn: {{group.bet_amount || 0 | number : '1.0-2'}}</div>
            </div>
          </ng-template>
        </p-dataView>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="sub-container">
    <h2>Weltmeister-Tipps:</h2>
    <p-accordion [multiple]="true">
      <p-accordionTab header="Weltmeister-Tipps" [selected]="true"
                      class="ui-accordion-header-cfx">
        <p-dataView [value]="champion_bets">
          <ng-template let-bet pTemplate="listItem">
            <div class="player-bet">
              <div class="player-bet-column">{{bet.champion_usernick}}</div>
              <div class="player-bet-column">{{bet.champion_name}}</div>
              <div class="player-bet-column">Gewinn: {{bet.champion_amount || 0 | number : '1.0-2'}}</div> 
            </div>
          </ng-template>
        </p-dataView>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
