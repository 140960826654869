<div class="cfx-card">
  <form name="form" (ngSubmit)="f.form.valid && reset()" #f="ngForm">
    <p-panel>
      <p-header>Passwort zurücksetzen</p-header>
      <div class="input-section">
        <div class="float-input">
          <span class="p-float-label">
          <input pInputText class="validate" id="usermail" name="usermail" type="email" [(ngModel)]="user.usermail"
                 size="25" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
          <label data-error="invalid address" for="usermail">E-Mail:</label>
          </span>
        </div>
      </div>
      <p-footer>
        <button pButton label="Zurücksetzen" [disabled]="!f.form.valid" type="submit"></button>
        <button pButton label="Abbrechen" class="p-button-secondary" [routerLink]="['/login']"></button>
      </p-footer>
    </p-panel>
  </form>
</div>
