export function getPwHash (): string {
  const pwHash = localStorage.getItem('cfx-pwhash')
  if (pwHash === null) {
    throw Error('pwHash is empty!')
  }
  return pwHash
}
export function getCurrentUser (allowEmpty: boolean = false): string {
  let currentUser = localStorage.getItem('cfx-user')
  if (currentUser === null) {
    if (!allowEmpty) {
      throw Error('user is empty!')
    } else {
      currentUser = ''
    }
  }
  return currentUser
}
