/**
 * Function that collects Error messages from the server to translates into messages shown to the user
 * @param {string} serverError
 * @returns {string}
 */
export function generateErrorMsg (serverError: string) {
  console.log('this is an error', serverError)
  if (serverError.toLowerCase().includes('unknown user or password' || 'user error')) {
    return 'Benutzer oder Passwort falsch!'
  } else if (serverError.toLowerCase().includes('date')) {
    return 'Datumsformat fehlerhaft!'
  } else if (serverError.toLowerCase().includes('unconfirmed user')) {
    return 'Bestätige zuerst den Benutzer bevor du Pseudokenner nutzt!'
  } else if (serverError.toLowerCase().includes('updating password')) {
    return 'Serverfehler, Passwort nicht geändert'
  } else if (serverError.toLowerCase().includes('illegal user register')) {
    return 'Benutzer existiert bereits oder wurde noch nicht bestätigt!'
  } else if (serverError.toLowerCase().includes('illegal user reset')) {
    return 'Benutzer existiert bereits oder wurde noch nicht bestätigt!'
  } else { // 'Invalid' || 'Invalid' || 'Illegal' || 'strange'
    return 'Server Fehler!'
  }
}
