import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { User } from '../../entity/user'
import { UserService } from '../../service/user.service'
import { MessageService } from 'primeng/api'

/**
 * Component to log in a user
 */
@Component({
  selector: 'pseudokenner-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  title = 'login'
  user = new User()
  /**
   * server salt value
   */
  serversalt: any
  /**
   * the url/route to return to when login successful
   */
  returnUrl: string

  /**
   * Constructor
   * @param {Router} router
   * @param {UserService} userService service that provides functions for {User} instances
   * @param {MessageService} messageService service to show (warn/success/error) messages to the user
   * @param {ActivatedRoute} route
   */
  constructor (private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly messageService: MessageService) {
  }

  ngOnInit () {
    this.userService.logout()
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/'
  }

  /**
   * process the login information
   */
  login () {
    this.userService.login(this.user).subscribe(
      data => {
        this.router.navigateByUrl(this.returnUrl)
      },
      error => {
        this.user = new User()
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: error })
      })
  }
}
