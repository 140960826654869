<div class="cfx-container cfx-dashboard">
  <h2>Hallo {{currentNickname}}!</h2>
  <div *ngIf="isAdmin()">
    <p>Du bist Admin und hast deshalb keine Rechte, um am Tippspiel teilzunehmen!</p>
  </div>
  <div *ngIf="!isAdmin()">
    <p>Willkommen zu Pseudokenner <span>{{ fullYear }}</span></p>
    <div *ngIf="currentGreeting">
      <h3>{{currentGreeting.title}}</h3>
      <p>{{currentGreeting.text}}</p>
    </div>
    <p>Dein Aktueller Gewinn beträgt <span class="payout-text">{{currentPayout || 0 | number : '1.0-2'}} CHF</span></p>
    <p>Im Jackpot befinden sich aktuell <span class="payout-text">{{currentJackpot || 0 | number : '1.0-2'}} CHF</span>
    </p>
    <div class="sub-container">
      <div class="ui-g ui-g-nopad sub-container-header">
        <div class="sub-container-header-text ui-g-6 ui-g-nopad">Weltmeister-Tipp:</div>
        <div class="ui-g-6 ui-g-nopad">

          <button (click)="saveChampionBet()" pButton label="Weltmeistertipp speichern"
                  class="sub-container-header-button ui-button-cfx ui-g-6 ui-g-offset-6 ui-g-nopad ui-sm-12 ui-sm-offset-0 pull-right"
                  icon="fa fa-check" [disabled]="isChampionStart()"></button>
        </div>
      </div>
      <h3 class="ui-g ui-g-nopad">
        <div class="ui-g-4 ui-sm-6 ui-g-nopad">
          <p-dropdown [options]="participants" [style]="{'width':'150px'}" [optionLabel]="name"
                      (onChange)="setChampionName(champion_bet)" placeholder="Dein Tipp"
                      [(ngModel)]="champion_bet" [disabled]="isChampionStart()"></p-dropdown>
        </div>
        <div class="ui-g-4 ui-g-offset-4 ui-sm-offset-0"></div>
      </h3>
    </div>
    <div class="sub-container">
      <div class="sub-container-header flex">
        <div class="sub-container-header-text flex-left">Spiele</div>
        <div class="flex-right">
          <button (click)="saveBets()" pButton label="Tipps speichern" [disabled]="!savePossible"
                  class="sub-container-header-button ui-button-cfx ui-g-6 ui-g-offset-6 ui-g-nopad ui-sm-12 ui-sm-offset-0 pull-right"
                  icon="fa fa-check"></button>
        </div>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab *ngFor="let category of categories" [hidden]="isPhaseHidden(category.category_id)"
                        header="{{category.category_label}}"
                        [selected]="true"
                        class="ui-accordion-header-cfx">
          <p-dataView [value]="filterGames(category.category_id)">
            <ng-template let-game pTemplate="listItem">
              <div class="float-input ui-g-nopad game-entry">
                <div class="game-section">
                  <div class="game-column">{{game.date | date:'d.MM.yyyy H:mm'}}</div>
                  <div class="game-column">{{game.location}}</div>
                </div>
                <div class="game-section">
                  <div class="game-column">{{game.team1}} - {{game.team2}}</div>
                  <div class="game-result">{{game.result_team1 | number}} :
                    {{game.result_team2 | number}}
                  </div>
                </div>
                <div class="game-section">
                  <div class="game-input">
                    Tipp:
                    <input type="text" [disabled]="isGameDeadline(game)" pInputText size="1" pattern="[0-9]*"
                           [(ngModel)]="game.bet_team1"/>
                    :
                    <input type="text" [disabled]="isGameDeadline(game)" pInputText size="1" pattern="[0-9]*"
                           [(ngModel)]="game.bet_team2"/>
                  </div>
                  <div class="game-column payout">Gewinn: {{game.bet_amount || 0 | number : '1.0-2'}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>
        </p-accordionTab>
      </p-accordion>
      <h3 *ngIf="!savePossible">Zum aktuellen Zeitpunkt können keine Spiele getippt werden!</h3>
      <p *ngIf="!savePossible">Die nächste Runde startet bald. Wir informieren dich über die üblichen Kanäle</p>
    </div>
  </div>
</div>
