import { BrowserModule } from '@angular/platform-browser'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'
import { RegisterComponent } from './component/register/register.component'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { routing } from './app.routes'
import { LoginComponent } from './component/login/login.component'
import { ScoreDashboardComponent } from './component/score_dashboard/score_dashboard.component'
import { BetDashboardComponent } from './component/bet_dashboard/bet_dashboard.component'
import { UserService } from './service/user.service'
import { AuthGuard } from './service/authentication.guard'
import { ActivationComponent } from './component/activation/activation.component'
import { ResetComponent } from './component/reset/reset.component'
import { RulesComponent } from './component/rules/rules.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MessageService } from 'primeng/api'
import { ChangePWComponent } from './component/changePW/changePW.component'
import { PlayersComponent } from './component/players/players.component'
import { HttpClientModule } from '@angular/common/http'
import { DropdownModule } from 'primeng/dropdown'
import { AccordionModule } from 'primeng/accordion'
import { TabViewModule } from 'primeng/tabview'
import { PasswordModule } from 'primeng/password'
import { InputTextModule } from 'primeng/inputtext'
import { ButtonModule } from 'primeng/button'
import { PanelModule } from 'primeng/panel'
import { MessagesModule } from 'primeng/messages'
import { ToastModule } from 'primeng/toast'
import { TableModule } from 'primeng/table'
import { DataViewModule } from 'primeng/dataview'

/**
 * Main app module of the cfx user management
 */
@NgModule({
  declarations: [
    AppComponent,
    ScoreDashboardComponent,
    BetDashboardComponent,
    LoginComponent,
    RegisterComponent,
    ChangePWComponent,
    ResetComponent,
    RulesComponent,
    ActivationComponent,
    PlayersComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    MessagesModule,
    ToastModule,
    PanelModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    TableModule,
    DropdownModule,
    AccordionModule,
    DataViewModule,
    TabViewModule,
    routing

  ],
  providers: [UserService, AuthGuard, MessageService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }],

  bootstrap: [AppComponent]
})
export class AppModule {
}
