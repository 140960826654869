import { Component } from '@angular/core'
import { UserService } from './service/user.service'
import { Router } from '@angular/router'
import { Message } from 'primeng/api'
import { getCurrentUser } from './helpers/helperFunctions'

/**
 * Main component that manages the display of all other components
 */
@Component({
  selector: 'pseudokenner-client',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'pseudokenner'
  messages: Message[] = []

  constructor (private readonly router: Router, private readonly userService: UserService) {
  }

  /**
   * Check if there are currently active credentials
   * @returns {boolean}
   */
  checkCredentials () {
    return getCurrentUser(true) !== ''
  }

  checkAdmin () {
    const cfxUser = getCurrentUser(true)
    return cfxUser !== '' ? JSON.parse(cfxUser).role === 2 : false
  }

  /**
   * Logs the current user out and navigates to the login screen
   */
  logout () {
    this.userService.logout()
    this.router.navigate(['/login'])
  }

  /**
   * gets the currently stored token of the logged in user
   * @returns {any}
   */
  get currentUser () {
    return JSON.parse(getCurrentUser()).user
  }
}
