<div class="cfx-container">
  <h2>Pseudokenner 2022 Regeln</h2>
  <h3>Das Original ist zurück! Das ultimative Pseudokenner-Toto &reg; für die WM2022.
    Eure Tipps gebt ihr somit wieder direkt über die Web-App ein. Einfach
    registrieren und los gehts. Ich danke Flavio für die Programmierung der App und freue mich auf eine spannende WM!</h3>

  <h4>Folgende Regeln gilt es dabei wie üblich zu beachten:</h4>
  <ul>
    <li>100% vom Einsatz (CHF 100.- pro Tippschein) werden unter den Spielern verteilt!</li>
    <li>Vor dem 1. Spiel der WM müssen die Vorrundenspiele und der Weltmeister getippt und der Einsatz bei mir
      angekommen sein
    </li>
  </ul>
  <h4>Was wird getippt?</h4>
  <ul>

    <li>Getippt wird jeweils das Resultat einer Partie</li>
    <li>Resultat in der Vorrunde = Endstand der Partie (Unentschieden möglich)</li>
    <li>Resultat 1/8-Final bis Final = Endstand nach 90 resp. 120 Minuten, wobei ein allfällig gewonnenes
      Penaltyschiessen 1 Tor zählt (Unentschieden nicht möglich)
      (Bsp. 0:0 nach 90 Min -> 1:1 nach Verlängerung -> Penaltyschiessen 5:4; der korrekte Tipp wäre demnach 2:1 - alles
      klar?)
    </li>
  </ul>

  <h4>Wie hoch ist der Einsatz?</h4>
  <ul>

    <li>Einsatz für Vorrunde = CHF 1.- pro Spiel (Total CHF 48.- für 48 Spiele)</li>
    <li>Einsatz für 1/8-Final = CHF 2.- pro Spiel (Total CHF 16.-) / Getippt wird erst, wenn die Paarungen feststehen
    </li>
    <li>Einsatz für 1/4-Final = CHF 3.- pro Spiel (Total CHF 12.-) / Getippt wird erst, wenn die Paarungen feststehen
    </li>
    <li>Einsatz für 1/2-Final = CHF 4.- pro Spiel (Total CHF 8.-) / Getippt wird erst, wenn die Paarungen feststehen
    </li>
    <li>Einsatz für Final = CHF 6.- / Getippt wird erst, wenn die Paarung feststeht</li>
    <li>Einsatz Weltmeister-Tipp = CHF 10.- / Getippt wird vor dem ersten Vorrundenspiel</li>
    <li>Gesamteinsatz beträgt CHF 100.-</li>
  </ul>

  <h4>Wie kann ich gewinnen?</h4>
  <ul>
    <li>Wer eine Begegnung richtig tippt erhält den gesamten Einsatz, um den es bei diesem Spiel geht - bei mehreren
      richtigen Resultaten wird der Gewinn für dieses Spiel gleichmässig aufgeteilt.
      (Bsp. Bei 50 Teilnehmern geht es bei jedem Vorrundenspiel um CHF 50.- Wird das Resultat 2x richtig getippt gewinnt
      jeder Glückliche CHF 25.-)
    </li>
    <li>Wird eine Begegnung von keinem Spieler richtig getippt, gelangt der volle Einsatz in den Jackpot.</li>
    <li>Der Gewinner des Jackpots ist derjenige Spieler, der am Ende der WM am meisten Gewinn erspielt hat (nicht
      unbedingt derjenige mit den meisten richtigen Tipps).
    </li>
  </ul>

  <h4>Wie bezahle ich meinen Einsatz?</h4>
  <ul><li>Am besten bar oder TWINT - nach Vereinbarung auch per Überweisung (dazu bitte kurzes Mail an mich)!</li></ul>

  <h4>Weitere Teilnehmer?</h4>
  <ul><li>Ihr könnt gerne weitere Freunde zum Mitspielen einladen - bitte übernehmt dann ggf. das Eintreiben des
    Einsatzes.</li></ul>

  <h2>Einsendeschluss Vorrunde: Samstag, 19. November 2022.</h2>

  <h3>Viel Erfolg beim Tippen und liebe Grüsse, Ursin (a.k.a. Pseudokenner)</h3>
</div>
