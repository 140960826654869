import { Component } from '@angular/core'
import { UserService } from '../../service/user.service'
import { Router } from '@angular/router'
import { User } from '../../entity/user'
import { MessageService } from 'primeng/api'

/**
 * Component to register a new user
 */
@Component({
  selector: 'pseudokenner-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent {
  title = 'register'
  confirmpassword: any
  user = new User()

  /**
   * Constructor
   * @param {Router} router
   * @param {UserService} userService service that provides functions for {User} instances
   * @param {MessageService} messageService service to show (warn/success/error) messages to the user
   */
  constructor (private readonly router: Router,
    private readonly userService: UserService,
    private readonly messageService: MessageService) {
  }

  /**
   * send registering information to the user service
   */
  register () {
    if (this.user.password.length < 8) {
      this.messageService.add({ severity: 'warn', summary: 'Registration nicht erfolgreich!', detail: 'Passwort zu kurz' })
    } else if (!this.pwMatch()) {
      this.messageService.add({ severity: 'warn', summary: 'Registration nicht erfolgreich!', detail: 'Passwort und Bestätigung stimmen nicht überein!' })
    } else {
      this.userService.register(this.user)
        .subscribe(
          data => {
            this.messageService.add({
              severity: 'success',
              summary: 'Registration erfolgreich!',
              detail: 'Überprüfe dein Postfach unter ' + this.user.usermail + ', um die Registration abzuschliessen.'
            })
            this.router.navigate(['/login'])
          },
          error => {
            this.user = new User()
            this.confirmpassword = ''
            this.messageService.add({ severity: 'error', summary: 'Error!', detail: error })
          })
    }
  }

  /**
   * make sure the password and password confirm input are equal
   * @returns {boolean}
   */
  pwMatch (): boolean {
    return this.user.password === this.confirmpassword
  }
}
