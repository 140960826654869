import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { MessageService } from 'primeng/api'
import { getCurrentUser } from '../helpers/helperFunctions'

/**
 * Authentication Guard that checks if the session has a currently valid token
 */
@Injectable()
export class AuthGuard implements CanActivate {
  tokenExpirationTime = 7200

  constructor (private readonly router: Router, private readonly messageService: MessageService) {
  }

  /**
   * Function that decides if a route can be activated/accessed or not
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   */
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const cfxUser = getCurrentUser(true)
    if (cfxUser !== '') {
      const currentUserJSON = JSON.parse(cfxUser)
      const tokenCreationTime = currentUserJSON.tokenCreated
      const currentDate = new Date()
      const sessionTime = (currentDate.getTime() / 1000) - tokenCreationTime
      if (sessionTime < this.tokenExpirationTime) {
        currentUserJSON.tokenCreated = currentDate.getTime() / 1000
        localStorage.setItem('cfx-user', JSON.stringify(currentUserJSON))
        return true
      }
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
      this.messageService.add({ severity: 'warn', summary: 'Bitte einloggen', detail: 'Deine Sitzung ist abgelaufen' })
      return false
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    if (state.url.indexOf('home') > 0 || state.url.indexOf('changePW') > 0) {
      this.messageService.add({ severity: 'warn', summary: 'Bitte einloggen', detail: 'Deine Sitzung ist abgelaufen' })
    }
    return false
  }
}
