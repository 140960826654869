import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from '../../service/user.service'
import { Game } from '../../entity/game'
import { MessageService } from 'primeng/api'
import * as _ from 'lodash'
import { getCurrentUser } from '../../helpers/helperFunctions'

/**
 * Main app component. Lists all the games for a user
 */
@Component({
  selector: 'pseudokenner-score-dashboard',
  templateUrl: './score_dashboard.component.html',
  styleUrls: ['./score_dashboard.component.css']
})
export class ScoreDashboardComponent implements OnInit {
  title = 'score-dashboard'
  /**
   * array of games shown on the list
   * @type {any[]}
   */
  games: Game[] = []
  originalGames: Game[] = []
  champion_bet: string
  champion_bet_name: any
  participants: any[]
  currentGreeting: any
  categories: any[]
  championship_start: Date
  savePossible = false

  /**
   * Constructor
   * @param {Router} router
   * @param {UserService} userService service that provides functions for {User} instances
   * @param {MessageService} messageService service to show (warn/success/error) messages to the user
   */
  constructor (private readonly userService: UserService, private readonly router: Router, private readonly messageService: MessageService) {
  }

  ngOnInit () {
    this.loadAllGames()
    this.getChampionBet()
  }

  /**
   * get the currently logged in user
   * @returns {any}
   */
  get currentUser () {
    return JSON.parse(getCurrentUser()).user ?? ''
  }

  get currentNickname () {
    return JSON.parse(getCurrentUser()).nickname ?? ''
  }

  get currentPayout () {
    return JSON.parse(getCurrentUser()).payout ?? ''
  }

  get currentJackpot () {
    return JSON.parse(getCurrentUser()).jackpot ?? ''
  }

  isAdmin (): boolean {
    return JSON.parse(getCurrentUser()).role === 2
  }

  get fullYear (): number {
    return new Date().getFullYear()
  }

  /**
   * load all games into the dashboard
   */
  loadAllGames () {
    this.userService.getAllGames(JSON.parse(getCurrentUser()).user).subscribe(
      data => {
        this.categories = data.categories
        this.savePossible = this.isSavePossible()
        this.championship_start = new Date(data.championship_start)
        if (data.games) {
          data.games.forEach(game => {
            game.date = new Date(game.date)
            return game
          })
        }
        this.games = data.games
        this.originalGames = _.cloneDeep(data.games)
        this.currentGreeting = data.greeting
      },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: err })
      }
    )
  }

  saveBets () {
    let skippedGames = 0
    const gamesToSave = this.games.map(game => {
      const currentDate = new Date()
      if (new Date(game.display_end) > currentDate && new Date(game.display_start) < currentDate) {
        if (new Date(game.game_category_deadline) > currentDate) {
          return game
        } else {
          skippedGames++
          return this.originalGames.find(originalGame => game.ID === originalGame.ID)
        }
      } else {
        return this.originalGames.find(originalGame => game.ID === originalGame.ID)
      }
    })
    this.userService.saveBetsForUser(JSON.parse(getCurrentUser()).user, gamesToSave).subscribe(
      data => {
        this.router.navigate(['/home'])
        if (skippedGames > 0) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Nicht alle Tipps wurden gespeichert (Deadline vorbei)',
            detail: ''
          })
        } else {
          this.messageService.add({ severity: 'success', summary: 'Tipps gespeichert!', detail: '' })
        }
      },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: err })
      }
    )
  }

  getChampionBet () {
    this.userService.getChampionBet(JSON.parse(getCurrentUser()).user).subscribe(
      data => {
        if (data.participants && data.champion_bet) {
          this.participants = data.participants
          this.champion_bet = data.champion_bet.bet
          this.setChampionName(data.champion_bet.bet)
        }
      },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: err })
      }
    )
  }

  saveChampionBet () {
    if (this.championship_start < new Date()) {
      this.messageService.add({ severity: 'error', summary: 'Weltmeister Tipp nicht gespeichert, Turnier bereits losgegangen!', detail: 'Du bist zu spät' })
    } else {
      this.userService.saveChampionBet(JSON.parse(getCurrentUser()).user, this.champion_bet).subscribe(
        data => {
          this.messageService.add({
            severity: 'success',
            summary: 'Weltmeistertipp gespeichert!',
            detail: this.champion_bet_name
          })
        },
        err => {
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: err })
        }
      )
    }
  }

  filterGames (category: number) {
    return this.games.filter(sub => {
      return sub.game_category === category
    })
  }

  private setChampionName (champion_id: any) {
    const champion_bet = this.participants.filter(participant => participant.value === champion_id)
    this.champion_bet_name = champion_bet.length > 0 ? champion_bet[0].label : ''
  }

  private isGameDeadline (game: Game) {
    return new Date(game.game_category_deadline) < new Date()
  }

  private isSavePossible (): boolean {
    const currentDate = new Date()
    if (this.categories) {
      const activeCategories = this.categories.filter(category => new Date(category.display_start) < currentDate && new Date(category.category_deadline) > currentDate)
      return activeCategories.length > 0
    }
    return false
  }

  private isChampionStart () {
    return this.championship_start < new Date()
  }

  private isPhaseHidden (category: number) {
    const groupGames = this.games.filter(game => game.game_category === category)
    const currentDate = new Date()
    return currentDate > new Date(groupGames[0].display_end) || currentDate < new Date(groupGames[0].display_start)
  }
}
