import { Component } from '@angular/core'
import { Router } from '@angular/router'

/**
 * Component to reset user's password
 */
@Component({
  selector: 'pseudokenner-rules',
  templateUrl: './rules.component.html'
})
export class RulesComponent {
  title = 'rules'

  constructor (private readonly router: Router) {
  }
}
