/**
 * Data structure for a user object
 */
export class User {
  usermail: string
  nickname: string
  password: string

  constructor () {
  }
}
